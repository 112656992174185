<template>
  <a-drawer
    :destroy-on-close="true"
    title="业务统计创建"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="统计周期" prop="datetimeRange">
        <a-range-picker
          v-model="form.datetimeRange"
          :disabled-date="disabledDate"
          format="YYYY-MM-DD HH:00"
          :show-time="{
            format: 'HH'
          }"
        >
          <a-icon slot="suffixIcon" type="calendar"></a-icon>
        </a-range-picker>
      </a-form-model-item>
      <a-form-model-item label="业务" prop="service">
        <service-select v-model="form.service"></service-select>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import { createServiceReport } from '@/api/report'
import ServiceSelect from '@/components/select/ServiceSelect.vue'

export default {
  name: 'CreateDrawer',
  components: {
    ServiceSelect
  },
  data () {
    return {
      visible: false,
      form: {
        name: '',
        service: undefined,
        datetimeRange: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        service: [
          {
            message: '请选择业务',
            required: true,
            trigger: 'change'
          }
        ],
        datetimeRange: [
          {
            message: '请选择统计周期',
            required: true,
            trigger: 'change'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    closeDrawer () {
      this.form = {
        name: '',
        service: undefined,
        datetimeRange: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    disabledDate (date) {
      return date && date > moment().endOf('hour')
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            service_id: this.form.service.id,
            datetime_from: this.form.datetimeRange[0].format('YYYY-MM-DD HH'),
            datetime_to: this.form.datetimeRange[1].format('YYYY-MM-DD HH')
          }
          this.loading = true
          createServiceReport(params)
            .then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
              this.closeDrawer()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
