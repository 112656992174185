import request from '@/utils/request'

const urlPrefix = '/services'

export function getService (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateService (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteService (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function generateServiceTopology (id) {
  return request.get(`${urlPrefix}/${id}/topology/generate`)
}

export function getServiceOSList (id, params) {
  return request.get(`${urlPrefix}/${id}/oses`, { params })
}

export function getServiceMiddlewareList (id, params) {
  return request.get(`${urlPrefix}/${id}/middlewares`, { params })
}

export function getServiceDatabaseList (id, params) {
  return request.get(`${urlPrefix}/${id}/databases`, { params })
}

export function getServiceApplicationList (id, params) {
  return request.get(`${urlPrefix}/${id}/applications`, { params })
}

export function getServiceSiteList (id, params) {
  return request.get(`${urlPrefix}/${id}/sites`, { params })
}

export function getServiceSSLCertificateList (id, params) {
  return request.get(`${urlPrefix}/${id}/ssl-certificates`, { params })
}

export function getServiceStatistic (id) {
  return request.get(`${urlPrefix}/${id}/statistic`)
}

export function getServiceTrend (id, params) {
  return request.get(`${urlPrefix}/${id}/trend`, { params })
}

export function getServiceList (params) {
  return request.get(urlPrefix, { params })
}

export function createService (data) {
  return request.post(urlPrefix, data)
}

export function getServiceAlertList (id, params) {
  return request.get(`${urlPrefix}/${id}/alerts`, { params })
}

export function getServiceAlertCount (id, params) {
  return request.get(`${urlPrefix}/${id}/alerts/count`, { params })
}

export function getServiceEventList (id, params) {
  return request.get(`${urlPrefix}/${id}/events`, { params })
}
