<template>
  <a-select
    :allow-clear="false"
    :filter-option="false"
    :label-in-value="true"
    :options="options"
    :show-search="true"
    v-model="selected"
    :disabled="disabled"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getServiceList } from '@/api/service'

export default {
  name: 'ServiceSelect',
  props: {
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 20
      },
      selected: undefined,
      options: [],
      total: 0,
      timer: null
    }
  },
  mounted () {
    if (this.value) {
      this.selected = {
        label: this.value.name,
        key: this.value.id
      }
    }
    this.fetch()
  },
  methods: {
    fetch () {
      getServiceList(this.form).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(service => {
          if (
            this.options.filter(option => option.value === service.id)
              .length === 0
          ) {
            this.options.push({
              key: service.id,
              label: service.name
            })
          }
        })
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    },
    change (value) {
      this.$emit('input', {
        id: value.key,
        name: value.label
      })
    }
  },
  watch: {
    value: {
      handler (v) {
        if (v) {
          this.selected = {
            key: v.id,
            label: v.name
          }
        }
      },
      deep: true
    }
  }
}
</script>
