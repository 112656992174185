<template>
  <a-drawer
    :destroy-on-close="true"
    :title="`定时任务${isUpdate ? '编辑' : '创建'}`"
    :visible="visible"
    :width="800"
    @close="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item label="业务" prop="service">
        <service-select v-model="form.service"></service-select>
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="cronTypes">
        <a-checkbox-group v-model="form.cronTypes">
          <a-checkbox value="daily">每日</a-checkbox>
          <a-checkbox value="weekly">每周</a-checkbox>
          <a-checkbox value="monthly">每月</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="reset">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { createServiceTaskReport, getServiceTaskReport, updateServiceTaskReport } from '@/api/report'
import { sourceTargets, sourceTypes } from '@/utils/const'
import ServiceSelect from '@/components/select/ServiceSelect'

export default {
  name: 'TaskInput',
  components: {
    ServiceSelect
  },
  data () {
    return {
      visible: false,
      form: {
        id: '',
        name: '',
        service: undefined,
        active: false,
        cronTypes: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        service: [
          {
            message: '请选择业务',
            required: true,
            trigger: 'change'
          }
        ],
        cronTypes: [
          {
            message: '请选择任务类型',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: {
        sourceType: sourceTypes.filter(item => {
          if (item !== 'application' && item !== 'site') { return item }
        }).map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      },
      loading: false,
      isUpdate: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getServiceTaskReport(id).then(res => {
          const data = res.data
          this.form = {
            id,
            name: data.name,
            service: data.service,
            active: data.active,
            cronTypes: data.cron_types
          }
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    reset () {
      this.visible = false
      this.isUpdate = false
      this.form = {
        name: '',
        service: undefined,
        active: false,
        cronTypes: undefined
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            cron_types: this.form.cronTypes,
            active: this.form.active,
            service_id: this.form.service.id
          }
          this.loading = true
          if (this.isUpdate) {
            updateServiceTaskReport(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.name = this.form.name
            createServiceTaskReport(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
